<template>
<div class="main-nuevaddjj">
  <div class="titulo">Nueva declaración jurada</div>

  <div class="motivo-section">
    <label for="anio">Año</label>&nbsp;
    <input type="number" id="anio" class="input-field" v-model="anio" @input="checkYear">&nbsp;
    <label for="motivo">Seleccione el motivo</label>&nbsp;
    <div v-show="buscandoMotivo">Recuperando motivo...</div>
    <select v-show="!buscandoMotivo" id="motivo" v-model="motivo" class="input-field">
      <option value="NORMAL">NORMAL</option>
      <option value="POR_BAJA">POR BAJA</option>
      <option value="POR_TRANSFERENCIA">POR TRANSFERENCIA</option>
    </select>
  </div>

  <button class="button" @click="iniciar" :disabled="!validYear">Iniciar Presentación</button>

  <div v-if="poseePresentacion" class="rectificar-section">
    <p>Ya existe una presentación realizada, ¿desea rectificarla?</p>
    <select v-model="rectificar" @change="updateData" class="dropdown">
    <option selected>NO</option>
    <option>SI</option>
    </select>&nbsp;
    <button v-if="rectificar == 'NO'" class="button" @click="ver">Ver Presentacion</button>
    <button v-if="rectificar == 'SI'" class="button" @click="rectificarPresentacion">Rectificar</button>
  </div>
  <br>

  {{error}}
</div>
</template>

<script setup>
import { onMounted, ref, defineProps, defineEmits } from "vue";
import axios from 'axios';
import { url } from '../config.js';

const props = defineProps({
  usuario: {
    type: Object,
    required: true,
  },
  token: {
    type: String,
    required: true,
  },
});

const emit = defineEmits(['onNuevaDDJJ']);

const loading = ref(false);
const poseePresentacion = ref(false);
const rectificar = ref('NO');
const error = ref("");
let anio = ref('');
const data = ref([]);
let motivo = ref('');
const currentYear = new Date().getFullYear();
const validYear = ref(false);
const buscandoMotivo = ref(false);

onMounted(async () => {
})

async function checkYear() {
  if (anio.value>2000 && anio.value <= currentYear) {
    buscandoMotivo.value = true;
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + props.token
    };
    let res = await axios.get(url+'/ddjj/motivo/'+anio.value, {headers: headers});
    motivo.value = res.data;
    buscandoMotivo.value = false;
    validYear.value = true;
  } else {
    validYear.value = false;
  }
  //if (anio.value == currentYear && poseePresentacion.value) {
  //  motivo.value = data.value.motivo || '';
  //}
}

async function iniciar() {
  console.log('iniciar');

  try {

    loading.value = true;
    error.value = '';

    //Validate year
    const currentYear = new Date().getFullYear();
    const validYearRangeStart = currentYear - 6;
    const validYearRangeEnd = currentYear;

    if (isNaN(anio.value) || anio.value < validYearRangeStart || anio.value > validYearRangeEnd) {
      loading.value = false;
      error.value = 'Año debe estar entre ' + validYearRangeStart + ' y ' + validYearRangeEnd;
      return;
    }

    if (!motivo.value) {
      loading.value = false;
      error.value = 'Debe completar el motivo';
      return;
    }

    const headers = {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + props.token
        };
    const payload = { periodo: anio.value, motivo: motivo.value};
    console.log(payload);
    let res = await axios.post(url+'/ddjj/nuevaPresentacion', payload, {headers: headers});

    loading.value = false;
    
    if (res.data.estado == 'Borrador') {
      console.log('Borrador');

      emit('onNuevaDDJJ', res.data);
      return;
    }
    
    data.value = res.data;
    poseePresentacion.value = true;
    motivo.value = data.value.motivo || '';

  } catch(e) {
    loading.value = false;
    error.value = 'Error: ' + e;
  }    
}

async function rectificarPresentacion() {
  loading.value = true;
  try {
    const headers = {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + props.token
        };
    const payload = { anio: anio.value, motivo: motivo.value };

    let resRectificar = await axios.post(url + '/ddjj/rectificarPresentacion/' + anio.value + '/' + (data.value.presentacion + 1), payload, {headers: headers});
    console.log(resRectificar);

    loading.value = false;
    emit('onNuevaDDJJ', resRectificar.data);
  } catch (e) {
    loading.value = false;
    error.value = 'Error: ' + e;
  }
}

async function ver() {
  emit('onNuevaDDJJ', data.value);
}

</script>

<style>
.main-nuevaddjj {
  color: white;
  padding: 20px;
}
.titulo {
  font-size: x-large;
  color: white;
  margin-bottom: 20px;
}
.form-container {
  margin-bottom: 20px;
  padding: 5px;
}
.input-field {
  padding: 8px;
  margin-bottom: 10px;
  background-color: white;
}
.button {
  background-color: white;
  color: black;
  border: 2px solid #000000;
  padding: 8px 16px;
  cursor: pointer;
}
.button:disabled {
  background-color: #e0e0e0; /* A lighter gray background */
  color: #a0a0a0; /* Lighter gray text color */
  border: 2px solid #a0a0a0; /* Lighter gray border */
  cursor: not-allowed; /* Show the "not-allowed" cursor */
}

.rectificar-section {
  margin-top: 20px;
}
.motivo-section {
  margin-top: 10px;
}

.dropdown {
  background-color: white;
  -webkit-appearance: auto;
}

</style>
